.MasterLayout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .HeaderContainer {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .HeaderLeft {
      display: flex;
      align-items: center;
      .MenuContainer {
        margin-left: 44px;
      }
      img {
        height: 34px;
      }
    }
  }
  .AppContentLayout {
    flex: 1;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .SegmentContainer {
      margin-bottom: 20px;
    }
    .GraphContainerLayout {
      display: flex;
      flex: 1;
      iframe {
        border: none !important;
        width: 100%;
      }
      .GraphWidgetContainer {
        width: 24%;
        height: 266px;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        display: flex;
        flex-direction: column;
        margin-right: 24px;
        .WidgetHeader {
          border-bottom: 1px solid #e7e7e7;
          height: 44px;
          padding: 0 16px;
          display: flex;
          align-content: center;
          justify-self: unset;
        }
        .WidgetContent {
          display: flex;
          align-items: center;
          justify-content: center;
          .GraphContainer {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
}

.ant-tabs-tab {
  padding: 6px 0 !important;
}

article {
  display: flex;
  align-items: center;
}
